.App-header {
  background-color: rgb(0, 0, 0);
  color: rgb(76, 167, 151);
  display: flex;
  justify-content: space-between;
  height: 75px;
  padding: 20px;
  padding-right: 40%;
  padding-left: 5%;
}

.App-header > h1 {
  color: rgb(76, 167, 151);
}

.App-intro {
  font-size: large;
}

h2 {
  text-align: center;
}

li {
  text-align: center;
  margin: 5px 0px;
}

ul {
  list-style-type: none;
  padding-inline-start: 0px;
}

form {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
}